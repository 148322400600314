<template>
  <CCard no-header>
    <CCardBody>
      <h3 v-text="method"></h3>
      <CAlert :color="alertType" v-if="message"> {{ message }}</CAlert>
      <CRow>
        <CCol sm="6">
          <CInput label="Integration Name" type="text" v-model="integration.name"></CInput>
        </CCol>
        <CCol sm="6">
          <CInput label="Price URL" type="text" v-model="integration.priceUrl"></CInput>
        </CCol>
        <CCol sm="6">
          <CInput label="Availability URL" type="text" v-model="integration.availabilityUrl"></CInput>
        </CCol>
        <CCol sm="6">
          <CInput label="Availability Overview URL" type="text" v-model="integration.availabilityOverviewUrl"></CInput>
        </CCol>
        <CCol sm="6">
          <CInput label="Save booking URL" type="text" v-model="integration.saveBookingUrl"></CInput>
        </CCol>
        <CCol sm="6">
          <CInput label="Save config URL" type="text" v-model="integration.saveConfigUrl"></CInput>
        </CCol>
        <CCol col="4">
          <CInputCheckbox label="Use Stayify Availability Management"
            :checked.sync="integration.useStayifyAvailabilityMgmt" />
        </CCol>
      </CRow>
      <h3 style="margin-top: 20px !important">
        <CIcon name="cilSettings" /> Custom Headers
        <hr />
      </h3>
      <CRow>
        <CButton color="primary" @click="addHeader" style="margin-bottom: 15px; margin-left: 1.23em">
          <CIcon name="cil-list" /> Add custom header
        </CButton>
      </CRow>
      <CRow>
        <CCol sm="6" v-for="(header, index) in headers" :key="index" style="display: flex; align-items: center">
          <label :for="'header-' + index"> Header Name</label>
          <CInput type="text" :id="'header-' + index" v-model="header.headerName"
            style="margin-left: 10px; width: 30rem" />
          <CButton style="margin-left: 10px" @click="eliminateHeader(header)" title="Remove custom header">
            <CIcon name="cilXCircle" />
          </CButton>
        </CCol>
      </CRow>



      <h3 style="margin-top: 20px !important">
        <CIcon name="cilSettings" /> Config fields
        <hr />
      </h3>
      <CRow>
        <CButton color="primary" @click="addConfig" style="margin-bottom: 15px; margin-left: 1.23em">
          <CIcon name="cil-list" /> Add custom config field
        </CButton>
      </CRow>
      <CRow>
        <CCol sm="6" v-for="(config, index) in configFields" :key="index" style="display: flex; align-items: center">
          <label :for="'config-' + index"> Config Name</label>
          <CInput type="text" :id="'config-' + index" v-model="config.headerName"
            style="margin-left: 10px; width: 30rem" />
          <CButton style="margin-left: 10px" @click="eliminateConfig(config)" title="Remove custom config">
            <CIcon name="cilXCircle" />
          </CButton>
        </CCol>
      </CRow>

      <h3 style="margin-top: 20px !important">
        <CIcon name="cilSettings" /> Custom Fields
        <hr />
      </h3>

      <CRow>
        <form-builder class="global" ref="formBuilder" type="template" v-model="configuration"></form-builder>
      </CRow>
      <CRow>
        <CCol col="9"></CCol>
        <CCol class="d-flex justify-content-end">
          <CButton color="secondary" @click="goBack" style="margin-right: 15px">
            <CIcon name="cil-list" /> Back to the list
          </CButton>
          <CButton color="primary" @click="create(true)">
            <CIcon name="cil-save" /> Save
          </CButton>
        </CCol>
      </CRow>
    </CCardBody>
  </CCard>
</template>

<script>
import axios from "axios";
import { FormBuilder } from "v-form-builder";
export default {
  components: { FormBuilder },
  name: "CreateOrUpdateIntegration",
  data: () => {
    return {
      emptyGuid: "00000000-0000-0000-0000-000000000000",
      method: "Create New Integration",
      headers: [],
      configFields: [],
      headerCount: 0,
      configCount: 0,
      formData: null,
      configuration: {},
      integration: {
        id: "00000000-0000-0000-0000-000000000000",
        name: null,
        priceUrl: null,
        availabilityUrl: null,
        availabilityOverviewUrl: null,
        saveBookingUrl: null,
        saveConfigUrl: null,
        useStayifyAvailabilityMgmt: false
      },
      // Alert
      alertType: "success",
      message: null,
    };
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    addHeader() {
      this.headers.push({ headerName: "", id: this.headerCount });
      this.headerCount = this.headerCount + 1;
    },
    eliminateHeader(header) {
      this.headers = this.headers.filter((item) => item.id != header.id);
      this.headerCount = this.headerCount - 1;
    },
    addConfig() {
      this.configFields.push({ headerName: "", id: this.configCount });
      this.configCount = this.configCount + 1;
    },
    eliminateConfig(header) {
      this.configFields = this.configFields.filter((item) => item.id != header.id);
      this.configCount = this.configCount - 1;
    },
    create(mustCreate) {
      if (mustCreate == true) {
        let self = this;
        let form = new FormData();
        form.append("id", self.integration.id);
        form.append("name", self.integration.name);
        form.append("priceUrl", self.integration.priceUrl);
        form.append("availabilityUrl", self.integration.availabilityUrl);
        form.append(
          "availabilityOverviewUrl",
          self.integration.availabilityOverviewUrl
        );
        form.append("saveBookingUrl", self.integration.saveBookingUrl);
        form.append("saveConfigUrl", self.integration.saveConfigUrl);
        form.append("useStayifyAvailabilityMgmt", self.integration.useStayifyAvailabilityMgmt);
        form.append(
          "serializedFormFields",
          JSON.stringify(this.configuration.controls)
        );
        form.append("allGeneratedForm", JSON.stringify(this.configuration));

        // Create a new array with only the headerName strings
        const customHeadersNames = self.headers.map(
          (header) => header.headerName
        );


        const configNames = self.configFields.map(
          (header) => header.headerName
        );
        debugger;
        form.append(
          "customHeadersNamesJson",
          JSON.stringify(customHeadersNames)
        );

        form.append(
          "configFieldsNamesJson",
          JSON.stringify(configNames)
        );

        axios
          .post(this.$apiAdress + "/v1/Integration", form)
          .then((response) => {
            self.integration.id = response.data;
                      self.alertType = "success";
          setTimeout(function () {
              self.message = null;
          }, 5000);
            self.message = "Successfully updated integration.";
          })
          .catch(function (error) {
                      self.alertType = "danger";
          setTimeout(function () {
              self.message = null;
          }, 10000);
            self.message = error;
          });
      }
    },

    sleep(milliseconds) {
      return new Promise((resolve) => setTimeout(resolve, milliseconds));
    },
    getIntegration(id) {
      let self = this;
      axios
        .get(this.$apiAdress + "/v1/Integration/" + id)
        .then(async function (response) {
          self.integration = response.data;
          self.configuration = JSON.parse(response.data.allGeneratedForm);
          response.data.customHeaders.forEach((item) => {
            self.headers.push({
              headerName: item.headerName,
              id: self.headerCount,
            });
            self.headerCount = self.headerCount + 1;
          });

          response.data.configFields.forEach((item) => {
            self.configFields.push({
              headerName: item.headerName,
              id: self.configCount,
            });
            self.configCount = self.configCount + 1;
          });


        })
        .catch(function (error) {
          self.message = error;
        });
    },
  },
  mounted: function () {
    let self = this;
    // Edit OR Create
    if (self.$route.params.id != this.emptyGuid) {
      self.method = "Edit Integration";
      self.getIntegration(self.$route.params.id);
    }
    self.headerCount = self.headers.length;
    self.configCount = self.configFields.length;
  },
};
</script>
<style>
.vue-form-builder .copyright-text {
  display: none;
}
</style>
